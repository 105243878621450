/* Estilos generales */


.inscription-page-inscripciones {
    width: 99vw;
    height: auto;
    background: linear-gradient(#528cff, #3f6fd8, #2a53b5, #1e4186);
    padding-bottom: 40px;
}

.header-form-row {
    align-items: center; /* Centra verticalmente el contenido de las columnas */
    text-align: center; /* Centra horizontalmente el texto */
    display: flex;
    justify-content: space-between; /* Distribuye el espacio entre el logo y el texto */

}

.header-form-logo {
    max-width: 38%; /* Asegura que el logo no exceda el ancho de la columna */
    padding-left: 20px; /* Espacio entre el logo y el texto */
    margin: 10px auto; /* Centra el logo horizontalmente */
    margin-top: 10px;

}

.header-form-title {
    font-size: 2.5rem; /* Tamaño del título */
    margin-top: 20px; /* Espacio en la parte superior del título */
    color: #ffffff !important; /* Color del texto del encabezado */
    margin-right: 20%;
}

.header-form-subtitle {
    font-size: 1.2rem; /* Tamaño del subtítulo */
    margin-right: 20%;

}
.containerForm-inscripciones {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 12px auto;
    max-width: 700px;
    width: 90%; /* Ancho del contenedor del formulario */
}


.inscription-page p {
    font-size: 1.2rem;
}

.bg-primary-form {
    background-color: #2e56a6 !important; /* Color de fondo del encabezado */
}

.text-white-form {
    color: #ffffff !important; /* Color del texto del encabezado */
}



.form-label {
    font-weight: bold;
    margin-top: 10px;
}

.form-control, .form-select, .textarea-form {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #69a6f1;
    border-radius: 4px;
    box-sizing: border-box;
}

.button-form {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
}

.button-form:hover {
    background-color: #0056b3;
}

/* Estilos para pantallas de 720px */
@media (max-width: 720px) {
    .header-form-title {
        font-size: 2rem; /* Reduce el tamaño del título */
        margin-right: 0%;

    }

    .header-form-subtitle {
        font-size: 1rem; /* Reduce el tamaño del subtítulo */
        margin-right: 0%;

    }
}

/* Estilos para pantallas de 680px */
@media (max-width: 680px) {
    .header-form-row {
        flex-direction: column; /* Cambia la dirección de Flexbox a columna */
        text-align: center; /* Centra el contenido horizontalmente */
    }

    .header-form-logo {
        margin-top: 15px;
        margin-bottom: 20px; /* Añade margen en la parte inferior del logo */
    }

    .card-title {
        display: flex;
    align-items: stretch;
    }

    .header-form-title {
        font-size: 1.8rem; /* Reduce aún más el tamaño del título */
        margin-right: 0%;
    }

    .header-form-subtitle {
        font-size: 0.9rem; /* Reduce aún más el tamaño del subtítulo */
        margin-right: 0%;
    }
}


/* estilos.css del modal*/
.modal-body {
    padding: 30px  10px;
    box-shadow: none;

}
.modal-xl-custom {
    max-width: 70%;
  }

  .close-btn-custom {
    font-size: 1.5rem;
    line-height: 1;
  }
  
 /* estilos.css */
.btn-footer-close {
    background-color: #6c757d; /* color gris oscuro */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-footer-close:hover {
    background-color: #565e64; /* un gris un poco más oscuro */
  }
  
  .btn-footer-close:active {
    background-color: #495057; /* un gris aún más oscuro */
  }
  
.ReactModalPortal > .ReactModal__Overlay--after-open{
    z-index: 2!important;
    max-width: 80%;
}