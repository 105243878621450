

.cardcate-card {
  margin: 0; 
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
  width: 180px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
  
.cardcate {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-around;
}

.cardcate-card:hover {
    background: linear-gradient(45deg, #ddeafc, #c3dbfa, #99c5f7);
    color: #ffffff; /* Color del texto al pasar el ratón */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Sombra más pronunciada al pasar el ratón */
    text-decoration: none;
}

.cardcate-icon {
    font-size: 3rem;
    margin-bottom: 10px;

}

.cardcate-title {
    font-size: 1.2rem;
    text-align: center;
    color: #2C3E50;
    text-decoration: none;

}

.cardcate-title:hover {
  text-decoration: none;


}


.cardcate-count {
    border-radius: 20px;
    font-size: 1.2rem;
}