.menu-collapse{
  overflow: hidden;
  max-width: 0;
  transition: max-height 0.5s ease-out;
}
.menu-open{
  max-width: 100%;
}
.center{
  margin: 0 auto !important;
}
.btn-round{
  border-radius: 60%;
}
.padding-top-50{
  padding-top: 50px;
}
.padding-top-10{
  padding-top: 10px;
}
.padding-top-20{
  padding-top: 20px;
}
.padding-bottom-20{
  padding-bottom: 20px;
}
.padding-left-10{
  padding-left: 10px;
}
.hide{
  display: none!important;
}