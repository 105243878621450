/* Estilos generales */

.students-description {
    font-size: 16px;
    color: #666; /* Color de texto secundario */
}


.student-table-container {
    background-color: #f7f7f7; /* Color de fondo principal */
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Sombra suave */
    max-width: 100vw;
}

.student-table-controls {
    align-items: center;
}

/* Estilos para botones */
.btn-NuevoEstudiante {
    background-color: #3f80ea;
    color: #fff; /* Color de texto del botón primario */
    border: none;
    border-radius: 5px;
    font-size: 14px;
    padding: 8px 16px;
    cursor: pointer;
}


.btn-DesactivarEstudiante {
    background-color: #e5a54b;;
    color: #fff !important; /* Color de texto del botón secundario */
    border: none;
    border-radius: 5px;
    font-size: 14px;
    padding: 8px 16px;
    cursor: pointer;
}
.btn-NuevoEstudiante:hover, .btn-DesactivarEstudiante:hover {
    filter: brightness(85%); /* Reducir brillo al pasar el mouse */
}

/* Asegurándonos de que el grupo de botones esté alineado a la derecha */
.student-table-controls .btn-group {
    margin-left: 10px;
}

/* Podrías agregar más estilos personalizados si lo necesitas, 
   como márgenes específicos entre los botones, etc. */
.student-table-controls .btn-Estudiante {
    margin-right: 10px;
}


/* Estilos para la tabla */
.students-datatable {
    background-color: #fff; /* Color de fondo de la tabla */
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Sombra suave para la tabla */
}
/* Estilo para el botón de editar */
.btn-edit {
    background-color: #e5a54b; /* Color de fondo amarillo */
    color: #fff; /* Texto en color blanco */
    border: none; /* Sin borde */
    border-radius: 4px; /* Bordes redondeados */
    padding: 3px 4px; /* Espaciado interno */
    cursor: pointer; /* Cursor al pasar el mouse */
}

/* Estilo para el botón de maximizar */
.btn-maximize {
    background-color: #3f80ea; /* Color de fondo azul */
    color: #fff; /* Texto en color blanco */
    border: none; /* Sin borde */
    border-radius: 4px; /* Bordes redondeados */
    padding: 3px 4px; /* Espaciado interno */
    cursor: pointer; /* Cursor al pasar el mouse */
}

/* Estilo para el botón de desactivar */
.btn-deactivate {
    background-color: #d9534f; /* Color de fondo rojo */
    color: #fff; /* Texto en color blanco */
    border: none; /* Sin borde */
    border-radius: 4px; /* Bordes redondeados */
    padding: 3px 4px; /* Espaciado interno */
    cursor: pointer; /* Cursor al pasar el mouse */
}

/* Cambiar color de fondo al pasar el mouse sobre los botones */
.btn-edit:hover,
.btn-maximize:hover,
.btn-deactivate:hover {
    filter: brightness(85%); /* Reducir brillo al pasar el mouse */
}


/* Estilos para la barra de búsqueda */
.form-control {
    border-radius: 5px;
}


/* Personalizar la barra de desplazamiento para navegadores basados en WebKit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
    width: 10px;  /* Ancho de la barra vertical */
    height: 10px;  /* Altura de la barra horizontal */
  }
  
  ::-webkit-scrollbar-thumb {
    background: #64646449;  /* Color del deslizador */
    border-radius: 5px;   /* Bordes redondeados del deslizador */
  }
  
  ::-webkit-scrollbar-track {
    background: #e0e0e0;  /* Color de fondo de la barra */
    border-radius: 5px;   /* Bordes redondeados de la barra */
  }
  

  
